@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("../fonts/feather/feather.css");
@import "./fonts/proxima-nova.scss";
@import "./fonts/new-spirit.scss";

h2,
h3,
h4,
h5 {
  font-family: "Proxima Nova", sans-serif;
  letter-spacing: normal;
}

h1 {
  font-family: "New Spirit", sans-serif !important;
  font-weight: normal;
  font-style: normal;
  font-size: 5.375rem; // 86px
  line-height: 90%;
  text-transform: uppercase;
}

.title {
  font-family: "New Spirit", sans-serif !important;
  font-weight: lighter;
  font-style: normal;
  font-size: 3.375rem;
  line-height: 110%;
  text-transform: uppercase;
  text-align: center;
}

.display-1, .display-2, .display-3, .display-4 {
  letter-spacing: normal;
}

h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 2.6875rem; // 43px
  line-height: 120%;
  &.bold {
    font-weight: 700;
  }
}

h3 {
  font-size: 1.1875rem; // 19px
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 120%;
  letter-spacing: 0.01em;
  &.subtitle {
    text-transform: uppercase;
  }
}

h4 {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 800;
  font-size: 1.1875rem; // 19px
  line-height: 120%;
}

p {
  font-family: "Proxima Nova", sans-serif;
}

button,
a {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 800;
}

.proxima-nova {
  font-family: "Proxima Nova", sans-serif !important;
}

.new-spirit {
  font-family: "New Spirit", sans-serif !important;
}

span {
  font-family: "Proxima Nova", sans-serif;
}

.text-content {
  color: $darkGray;
}

.text-muted {
  color: $gray !important;
}

.text-smaller {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.07px;
}

.text-smaller-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 19.07px;
}