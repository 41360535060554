:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --input-border-color: #{$input-border-color};
  --default-px-size: 1rem;
  --dark-gray: #{$darkGray};
  --dark-red: #{$darkRed};
  --gray: #{$gray};
  --yellow: #{$yellow};
  --rust: #{$rust};
  --retro-blue: #{$retro_blue};
  --rust-dis: #{lighten($rust, 15%)};
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.btn {
  font-weight: 800;
  font-size: 1.1875rem; // 19px
  line-height: 120%;
  border-radius: $border-radius;
  padding: 1.1875rem 0; // we will use flex
  max-height: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  &.w-210 {
    width: 100%;
    max-width: 210px;
  }
}

.btn-sm {
  @extend .btn;
  max-height: 37px;
  padding: 8px 0;
}

.btn-xs {
  @extend .btn;
  max-height: 25px;
  padding: 1px 0;
  border-radius: 2.5px;
}

.btn-primary {
  color: $white;
  background-color: $primary;

  &:hover {
    background: $white;
    color: $primary;
  }
}

.btn-secondary {
  color: $primary;
  background-color: $secondary;
}

.btn-image {
  vertical-align: text-bottom;
  .icon {
    margin-right: 6.67px;
  }
  &.btn-primary {
    .icon path {
      fill: $white;
    }
    &:hover {
      .icon path {
        fill: $white;
      }
    }
  }
  &.btn-outline-primary {
    .icon path {
      fill: $primary;
    }
    &:hover {
      .icon path {
        fill: $white;
      }
    }
  }
}

.btn-rounded-circle {
  width: 40px;
  height: 40px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: $primary;
}

label.form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: $primary;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  padding: 12px 26px;
  background-color: transparent;
  resize: none;
  border: 2px solid #f2f4f2;
  border-radius: $border-radius;
  &:disabled {
    color: $darkGray;
  }
}

.form-control-lg {
  height: 48px;
}

.input-group-append,
.input-group-prepend {
  .input-group-text {
    border: 2px solid #f2f4f2;
    padding: 0 !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0.3125rem !important;
    .btn {
      font-size: 16px;
      line-height: 150%;
      padding: 0 0.5rem;
      border: 0 none;
    }
  }
}

.input-group.input-group-merge {
  & > .form-control-border-bottom {
    & + .input-group-append {
      .input-group-text {
        color: #a9e1f7;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}

.loading-overlay {
  z-index: 1050;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba($gray, 0.25);
}


.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($primary, 0.2);
  border-radius: 50%;
  border-top-color: $primary;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

.inner-loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($primary, 0.2);
  border-radius: 50%;
  border-top-color: $primary;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: absolute;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

hr {
  border-top: 1px solid $black;
}

.fw-800 {
  font-weight: 800 !important;
}

.card {
  box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 0 none;
  &.bare {
    box-shadow: none;
    background: transparent;
  }
}

.text-dark-gray {
  color: $darkGray !important;
}

.text-dark-red {
  color: $darkRed !important;
}

// Toastify
.Toastify__toast-container {
  width: auto !important;
  .Toastify__toast-body {
    font-family: "Proxima Nova", sans-serif;
    letter-spacing: normal;
  }
}

.Toastify__toast.add-to-cart {
  height: 150px;
  box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  .Toastify__toast-body {
    width: 100%;
  }
}

.Toastify__toast.contact-success {
  background: #3ab63d !important;
}

.Toastify__toast--dark {
  background: $primary;
  color: white;
}
.Toastify__toast--info {
  background: $retro_blue;
  color: $primary;
}
.Toastify__toast--success {
  background: white;
  color: $primary;
}
.Toastify__toast--warning {
  background: $yellow;
  color: $primary;
}
.Toastify__toast--error {
  background: $rust;
  color: white;
}

// Alerts
.alert-soft-info {
  border: 0 none;
  color: $primary;
  background-color: $retro_blue;
  padding: 16px 24px;
  border-radius: 5px;
}

.alert-soft-success {
  border: 0 none;
  color: white;
  background-color: $green;
  padding: 16px 24px;
  border-radius: 5px;
}
.alert-soft-danger {
  border: 0 none;
  color: white;
  background-color: $rust;
  padding: 16px 24px;
  border-radius: 5px;
}
.alert-threashold-danger {
  border: 0 none;
  color: black;
  background-color: $rust;
  padding: 16px 24px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
}
.alert-inactive-user {
  color: $darkRed !important;
  text-align: center;
  font-weight: 600;
  font-style: italic;
}
// body.modal-open > :not(.modal) {
//   -webkit-filter: blur(20px);
//   -moz-filter: blur(20px);
//   -o-filter: blur(20px);
//   -ms-filter: blur(20px);
//   filter: blur(20px);
// }
.modal-backdrop {
  background: rgba(6, 25, 8, 0.1);
}
.modal {
  backdrop-filter: blur(20px);
}

@media only screen and (max-width: 767px) {
  .Toastify__toast.add-to-cart {
    .Toastify__toast-body {
      padding: 12px 0px;
    }
  }
}

.meals-carousel {
  .card {
    min-width: 260px;
	margin-right: 16px;
    height: 240px;
    @media screen and (min-width: 1280px) {
      max-width: 332px;
      width: 100%;
	  height: 274px;
	  margin-right: 21px;
    }
  }
}