$cards-per-line: (
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
    xxl: 3
);

@each $name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
        .card-deck .card {
            flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
        }
    }
}

$meal-cards-per-line: (
    xs: 1,
    sm: 1,
    md: 3,
    lg: 4,
    xl: 5,
    xxl: 5
);

@each $name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
        .meals.card-deck .card {
            flex: 0 0 calc(#{100/map-get($meal-cards-per-line, $name)}% - #{$grid-gutter-width});
        }
    }
}

$blog-cards-per-line: (
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    xxl: 4
);

@each $name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
        .blogs.card-deck .card {
            flex: 0 0 calc(#{100/map-get($blog-cards-per-line, $name)}% - #{$grid-gutter-width});
        }
    }
}