@font-face {
	font-family: 'New Spirit';
	src: url('../../fonts/new-spirit/web/NewSpirit-RegularCondensed.eot') format('embedded-opentype'),
        url('../../fonts/new-spirit/desktop/NewSpirit-RegularCondensed.otf') format('opentype'),
        url('../../fonts/new-spirit/web/NewSpirit-RegularCondensed.ttf') format('truetype'),
        url('../../fonts/new-spirit/web/NewSpirit-RegularCondensed.woff') format('woff'),
        url('../../fonts/new-spirit/web/NewSpirit-RegularCondensed.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'New Spirit';
	src: url('../../fonts/new-spirit/web/NewSpirit-SemiBold.eot') format('embedded-opentype'),
        url('../../fonts/new-spirit/desktop/NewSpirit-SemiBold.otf') format('opentype'),
        url('../../fonts/new-spirit/web/NewSpirit-SemiBold.ttf') format('truetype'),
        url('../../fonts/new-spirit/web/NewSpirit-SemiBold.woff') format('woff'),
        url('../../fonts/new-spirit/web/NewSpirit-SemiBold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}