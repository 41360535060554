.react-select .react-select__dropdown-indicator,
.react-select
  .react-select__control--is-focused
  .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select
  .react-select__control--is-focused
  .react-select__clear-indicator {
  outline: initial;
  box-shadow: initial;
  &:active,
  &:focus,
  &:hover {
    color: var(--primary) !important;
    .icon {
      path {
        fill: var(--primary) !important;
      }
    }
  }
}

.react-select__option--is-selected {
  background: var(--dark-gray) !important;
  color: var(--primary) !important;
}

.react-select__option--is-focused {
  color: var(--primary) !important;
  background: var(--secondary) !important;
}

.react-select__control {
  box-shadow: none !important;
  height &:hover {
    border-color: var(--gray) !important;
  }
}

.react-select__control--is-focused {
  border-color: var(--primary) !important;
  &:hover {
    border-color: var(--primary) !important;
  }
}

.react-select.is-invalid .react-select__control {
  // border-radius: 0.25rem !important;
  border-color: var(--danger) !important;
}
.react-select__menu {
  margin-top: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  box-shadow: none !important;
  z-index: 2;
  .react-select__menu-list {
    padding-bottom: 8px;
    padding-top: 8px;
    .react-select__option {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
}

.react-select__option {
  padding-left: 26px;
}

.react-select {
  &.height-lg {
    .react-select__control {
      height: 48px;
    }
  }
}
