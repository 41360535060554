@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova It.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova It.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova Semibold.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova Semibold It.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova Semibold It.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova Bold.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova Bold It.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova Bold It.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova Extrabold.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova Extrabold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova Extrabold It.otf') format('opentype'),
        url('../../fonts/proxima-nova/Proxima Nova Extrabold It.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/proxima-nova/Proxima Nova Thin.otf') format('opentype');
	font-weight: 200;
}