//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!

// CRA 4 fails to compile issue: https://github.com/facebook/create-react-app/issues/10022
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  xxl: 1440px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1290px
);

$grid-gutter-width: 24px !default;

// Primary colors
$black: #061908 !default;
$retro_blue: #A9E1F7 !default;
$white: #ffffff !default;

// Secondary colors
$gray: #F2F4F2;
$yellow: #FFEE55;
$rust: #DF8F5D;

// Tertiary
$darkGray: #C0C6C2;
$darkRed: #B94A48;
$bgGray: #E5E5E5;

$primary: $black;
$secondary: $retro_blue;
$success: $black !default;
$info: $retro_blue !default;
$warning: $yellow !default;
$danger: $rust !default;
$light: $gray !default;
$dark: $black !default;

$link-color: $retro_blue !default;

$font-size-base: 1rem;
// $border-radius: 0.3125rem !default; // 5px
$border-radius: 0.15625rem !default; // 2.5px

$path-to-fonts: "/assets/fonts" !default;
$path-to-img: "/assets/img" !default;
$input-border-color: $gray;

$spacer: 1.5rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: // 3px
    (
        $spacer * 0.125,
      ),
    2: // 6px
    (
        $spacer * 0.25,
      ),
    3: // 12px
    (
        $spacer * 0.5,
      ),
    4: $spacer,
    // 24px
    5: // 36px
    (
        $spacer * 1.5,
      ),
    6: // 72px
    (
        $spacer * 3,
      ),
    7: // 108px
    (
        $spacer * 4.5,
      ),
    8: // 216px
    (
        $spacer * 9,
      ),
    2px: (
      $spacer * 0.083333333,
    ),
    4px: (
      $spacer * 0.166666667,
    ),
    8px: (
      $spacer * 0.333333333,
    ),
    16px: (
      $spacer * 0.666666667,
    ),
    32px: (
      $spacer * 1.333333333,
    ),
    48px: (
      $spacer * 2,
    ),
    64px: (
      $spacer * 2.666666667,
    ),
    140px: (
      $spacer * 5.833333333,
    )
  ),
  $spacers
);
$font-family-sans-serif: 'Proxima Nova', sans-serif !default;